<template>
  <div class="errPage-container">
    <div>
        <img src="@/assets/images/401_images/401.gif" width="313" height="428" alt="Girl has dropped her ice cream.">
         <h2>没有权限查看该页面</h2>
     </div> 
  </div>
</template>

<script>
export default {
  name: 'Page401',
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({ path: '/' })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .errPage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;;
    .pan-back-btn {
      background: #008489;
      color: #fff;
      border: none!important;
    }
    .pan-gif {
      margin: 0 auto;
      display: block;
    }
    .pan-img {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
    .text-jumbo {
      font-size: 60px;
      font-weight: 700;
      color: #484848;
    }
    .list-unstyled {
      font-size: 14px;
      li {
        padding-bottom: 5px;
      }
      a {
        color: #008489;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>
